<template>
  <div class="wrapper">
    <div class="page">
      <div id="loader"></div>
      <div class="page-inner" id="main_page">
        <div class="va-card mb-4">
          <div class="dash_card_body pad-lr-0 ov-h">
            <div class="header_section d-block padd-30 pt-0 pb-0">
              <div class="w-100 t-wrap">
                <h4 class="menu_header">Store List</h4>
              </div>

              <div class="back-btn">
                <router-link :to="{ name: 'storelist' }"><i class="fa fa-arrow-left" aria-hidden="true"></i>
                </router-link>
                <span>{{ title }}</span>
              </div>
            </div>

            <div class="create_items_section">
              <form enctype="multipart/form-data" @submit.prevent="createStore">

                <div class="row">
                  <!--Left-->
                  <div class="col-md-6 bd-right pad-lr-0">
                    <div class="pl-5 pr-4 bd-b">
                      <div class="form-group">
                        <label>Title</label>
                        <div>
                          <input v-model="title" @blur="v$.title.$touch" class="form-control" />
                        </div>
                        <div class="input_error" v-if="v$.title.$error">Title is required.</div>
                      </div>
                      <div class="form-group">
                        <label>Description</label>
                        <div>
                          <editor v-model="description" :init="{
                            height: 400,
                            branding: false
                          }" />
                        </div>
                      </div>


                    </div><!-- END Title and Desc-->

                    <div id="image-loading-cover" class="pl-5 pr-4 bd-b">
                      <h2 class="section-title">Location Image</h2>
                      <div data-baseweb="form-control" class="al dw">
                        <div class="image_div">
                          <div class="img_preview" style="">
                            <div tabindex="0"
                              style="display: flex; justify-content: center; width: 100%; height: 100%; border: 2px dashed rgb(229, 229, 229); background: rgb(247, 247, 247) none repeat scroll 0% 0%; flex-direction: column; align-items: center; padding: 20px 0px; box-sizing: border-box;">
                              <img class="image_responsive" v-if="images" :src="images" />
                            </div>
                          </div>
                          <div class="image_content">
                            <div class="image_button">
                              <input type="file" style="border:none;" accept="image/*" @change="uploadImage($event)"
                                id="file-input" class="form-control" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div id="image-loader"> </div>
                    </div><!-- END Image-->
                    <div class="pl-5 pr-4 bd-b">
                      <h2 class="section-title">Location Details</h2>
                      <div class="row">
                        <div class="col-md-12">
                          <div class="form-group">
                            <label>Address</label>
                            <div>
                              <!-- <input type="text" @input="$emit('update:address',$event.target.value)" id="store_address"
                                class="form-control" :value="address" ref="addressRef"/> -->

                              <input   id="store_address" class="form-control"  />
                            </div>
                            <div class="input_error" v-if="v$.address.$error">Address is required.</div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>Longitude</label>
                            <div>
                              <input v-model="longitude" id="longitude" @blur="v$.longitude.$touch" class="form-control" />
                            </div>
                            <div class="input_error" v-if="v$.longitude.$error">Longitude is required.</div>

                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>Latitude</label>
                            <div>
                              <input v-model="latitude" id="latitude"  @blur="v$.latitude.$touch" class="form-control" />
                            </div>
                            <div class="input_error" v-if="v$.latitude.$error">Latitude is required.</div>

                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>Phone</label>
                            <div>
                              <input v-model="phone" class="form-control" />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>Status</label>
                            <div>
                              <select v-model="status" class="form-control">
                                <option value="1">Published</option>
                                <option value="2">Unpublished</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>Delivery Options</label>
                            <div>
                              <select v-model="deliverOptions" class="form-control">
                                <option value="all"> All</option>
                                <option value="pickup"> Store Pickup</option>
                                <option value="delivery"> Delivery</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <label for="">Parent Store</label>
                          <div>
                            <Multiselect v-model="parentBranch" :close-on-select="true" :options="strsoptions"
                              :custom-label="name" :searchable="true"
                              :placeholder="'Parent Stores (' + parentStores?.length + ')'" label="name"
                              track-by="name">
                            </Multiselect>
                          </div>
                        </div>
                      </div>


                    </div><!-- Location Details-->
                    <div class="pl-5 pr-4 pt-4">
                      <h2 class="section-title">Operating Hours</h2>
                      <p class="mb-4">You can choose operating times per day, or close ordering all together. If you
                        stop
                        ordering, opening hours will be ignored</p>
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label>Stop Orders?</label>
                            <label style="float:right;" class="switch">
                              <input type="checkbox" v-model="stopOrders" v-bind:true-value="true"
                                v-bind:false-value="false" />
                              <span class="slider round"></span>
                            </label>

                          </div>
                        </div>
                      </div>

                      <div class="operating_hours" v-show="!stopOrders">
                        <div class="row">
                          <div class="col-md-3">
                            <label class="b-label">Day of week</label>
                          </div>
                          <div class="col-md-3">
                            <label class="b-label">Closed</label>
                          </div>
                          <div class="col-md-3">
                            <label class="b-label">Opening Time</label>
                          </div>
                          <div class="col-md-3">
                            <label class="b-label">Closing Time</label>
                          </div>
                        </div>


                        <div class="row" v-for="(day, idx) in dayOfWeek" :key="idx">
                          <div class="col-md-3">
                            <span>{{ day.value }}</span>
                          </div>
                          <div class="col-md-3">
                            <div class="form-group">
                              <select v-model="operationDayStatus[day.key]" class="form-control">
                                <option :value="true">Open</option>
                                <option :value="false">Closed</option>
                              </select>
                            </div>

                          </div>
                          <div class="col-md-3">
                            <select class="form-control" v-model="startTime[day.key]">
                              <option v-for="time in startTimes" :key="time.key" :value="time.value">{{ time.key }}
                              </option>
                            </select>
                          </div>
                          <div class="col-md-3">
                            <select class="form-control" v-model="endTime[day.key]" name="end_time[]">
                              <option v-for="(etime, index) in endTimes" :key="index" :value="etime.value">{{ etime.key
                              }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>

                    </div><!-- operating hours-->

                  </div>
                  <!--Right Col-->
                  <div class="col-md-6 pad-l-0">
                    <div class="pl-4 pr-5 bd-b">
                      <h2 class="section-title">Pesapal Settings</h2>
                      <div class="form-group">
                        <label>Pesapal Consumer Key</label>
                        <div>
                          <input v-model="consumerKey" class="form-control" />
                        </div>
                      </div>
                      <div class="form-group">
                        <label>Pesapal Consumer Secret</label>
                        <div>
                          <input v-model="consumerSecret" class="form-control" />
                        </div>
                      </div>
                      <div class="form-group">
                        <label>Pesapal Mobile Guid</label>
                        <div>
                          <input v-model="mobileGuid" class="form-control" />
                        </div>
                      </div>
                      <div class="form-group">
                        <label>Pesapal POS Config Name</label>
                        <div>
                          <input v-model="posConfigName" class="form-control" />
                        </div>
                      </div>
                      <div class="form-group">
                        <label>Printer Ip</label>
                        <div>
                          <input v-model="printerIp" class="form-control" />
                        </div>
                      </div>

                      <div class="form-group">
                        <label>Printer Port</label>
                        <div>
                          <input v-model="printerPort" class="form-control" />
                        </div>
                      </div>
                      <div class="form-group">
                        <label>Pesapal POS System</label>
                        <div>
                          <label class="checkbox-inline">
                            <input type="radio" v-model="pos" value="0"  > &nbsp; None &nbsp; &nbsp;
                          </label>
                          <label class="checkbox-inline" v-for="pos_system in posConfigs" :key="pos_system.id">
                            <input type="radio" v-model="pos" :id="'pos_system'+pos_system.id" :value="pos_system.id" > &nbsp; {{pos_system.name}} &nbsp; &nbsp;
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="pl-4 pr-5 pt-4 ">
                      <h2 class="section-title">Shipping Settings</h2>
                      <div class="form-group">
                        <label>Courier Api Key</label>
                        <div>
                          <input v-model="courierKey" class="form-control" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                <div class="submit_btn_section">
                  <div class="row bt-b">
                    <div class="col-md-12">
                      <div class="form-group ml-2r mt-3">
                        <button type="submit" class="btn btn-orange mr-3">
                          <span v-if="spinner" v-html="searchspinner"></span> <span v-else>Save </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script >
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { useToast } from "vue-toastification";
// import {onMounted, ref } from 'vue';
import ReusableFunctionMixins from '@/mixins/ReusableFunctionMixins';
import ReusableDataMixins from '@/mixins/ReusableDataMixins';
import Multiselect from '@vueform/multiselect';


import "tinymce/tinymce";
import "tinymce/themes/silver";
import "tinymce/icons/default";
import "tinymce/skins/ui/oxide/skin.css";
import Editor from '@tinymce/tinymce-vue';


const toast = useToast();
export default {

  components: {
    'editor': Editor,
    Multiselect
  },
  setup() {
    return { v$: useVuelidate() }
  },
  metaInfo() {
    return {
      script: [{
        src: `https://maps.googleapis.com/maps/api/js?key=AIzaSyDTbUDGuqSCHowgN2ukWV-IYyKVmBVTENw&libraries=geometry,places`,
        async: true,
        defer: true
      }]
    }
  },

  data() {
    return {
      stopOrders: false,
      title: '',
      address: '',
      phone: '',
      latitude: '',
      longitude: '',
      status: "1",
      deliverOptions: 'all',
      file: '',
      courierKey: '',

      description: '',
      spinner: false,
      searchspinner: "<i class='fa fa-spinner fa-spin '></i> Saving...",
      imageUrl: '',
      operatingHoursIcon: '<i class="fas fa-angle-down"></i>',
      showOperatingHrs: false,
      showPesapalSettings: false,
      pesapalSettingsIcon: '<i class="fas fa-angle-down"></i>',
      iconup: '<i class="fas fa-angle-up"></i>',
      images: null,
      dayOfWeek: [{ "key": "monday", "value": "Monday" }, { "key": "tuesday", "value": "Tuesday" }, { "key": "wednesday", "value": "Wednesday" }, { "key": "thursday", "value": "Thursday" }, { "key": "friday", "value": "Friday" }, { "key": "saturday", "value": "Saturday" }, { "key": "sunday", "value": "Sunday" }],
      startTime: [],
      endTime: [],
      operationDayStatus: [],
      consumerKey: '',
      consumerSecret: '',
      parentBranch: '',
      mobileGuid: '',
      strsoptions: [],
      parentStores: [],
      posConfigName: '',
      posConfigs:[],
      pos: 0,
      printerPort:"",
      printerIp:""

    };
  },
  // watch:{
  //   address(){
  //     this.getLocations()
  //   }
  // },
  async mounted() {
    for (let x = 0; x < this.dayOfWeek.length; x++) {
      this.operationDayStatus[this.dayOfWeek[x].key] = true;
      this.startTime[this.dayOfWeek[x].key] = "7:30"
      this.endTime[this.dayOfWeek[x].key] = "20:00"
    }
    
    this.getParentstore();
    this.getPosConfigs();
    try{
        const url = `${this.dashBaseUrl}/configs/${this.merchantID}`;
        const response = await this.axios.get(url, this.apiRequestConfig())
        if (response.status === 200 ) {
            let countryCode = response.data.data.app_settings.country_code;
            countryCode = (countryCode !=undefined && countryCode !='') ? countryCode : "ke"
            const autocomplete = new window.google.maps.places.Autocomplete(document.getElementById("store_address"));
            autocomplete.setComponentRestrictions({ 'country': [countryCode] });
            autocomplete.setFields(['geometry', 'icon', 'name', 'formatted_address']);
            new window.google.maps.event.addListener(autocomplete, 'place_changed', function () {
                  const results = autocomplete.getPlace();
                  this.address = document.getElementById("store_address").value
                  const lat = results.geometry.location.lat();
                  const long = results.geometry.location.lng();	
                  document.getElementById("longitude").value = long
                  document.getElementById("latitude").value = lat
            });
        }
    }catch(err){
        //
        console.log("error", err) 
    }
  },

  computed: {
    startTimes() {
      const startTimeList = []
      for (let i = 1; i <= 23; i++) {
        const time1 = this.convertTime(i)
        let ampmarrival = ''
        if (i >= 12) {
          ampmarrival = 'PM'
        } else {
          ampmarrival = 'AM'
        }
        const constime241 = i.toString() + ':00'
        const constime = time1.toString() + ':00 ' + ampmarrival
        startTimeList.push({ 'value': constime241, 'key': constime })
        const constime1 = time1.toString() + ':30 ' + ampmarrival
        const constime242 = i.toString() + ':30'
        startTimeList.push({ 'value': constime242, 'key': constime1 })
      }

      return startTimeList
    },
    endTimes() {
      const endTimeList = [];
      for (let j = 23; j > 0; j--) {
        const time1 = this.convertTime(j);
        let ampmarrival = '';
        if (j > 12) {
          ampmarrival = 'PM';
        } else {
          ampmarrival = 'AM';
        }
        const constime241 = j.toString() + ':30';
        const constime1 = time1.toString() + ':30 ' + ampmarrival;
        endTimeList.push({ 'value': constime241, 'key': constime1 });
        const constime242 = j.toString() + ':00'
        const constime = time1.toString() + ':00 ' + ampmarrival
        endTimeList.push({ 'value': constime242, 'key': constime })
      }
      return endTimeList
    }

  },
  validations() {
    return {
      title: { required },
      address: { required },
      latitude: { required },
      longitude: { required }
    }
  },
  methods: {
    async getSettings(){
            
            
            // 
        },
    async getPosConfigs() {
        const config = {
            headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
        };
        let url = `${this.dashBaseUrl}/pos-configs/${this.merchantID}`;
        url = new URL(url);
        url.searchParams.set('status', 1);
        this.axios.get(url, config).then((response) => {
            if (response.status === 200) {
                this.posConfigs = response.data.data.data
            }
        }).catch((error) => {
            if (error.response.status === 401) {
                this.$router.push({
                    name: 'login'
                });
            }
        });
        // 
    },
    changePesapalIcon() {
      if (!this.showPesapalSettings) {
        this.showPesapalSettings = true;
        this.pesapalSettingsIcon = this.iconup;
      } else {
        this.pesapalSettingsIcon = '<i class="fas fa-angle-down"></i>';
        this.showPesapalSettings = false;
      }
    },
    getLocation() {
      const geocoder = new window.google.maps.Geocoder();
      const address = document.getElementById('store_address').value;
      this.address = address
      geocoder.geocode({ address: address }, (results, status) => {
        if (status === 'OK') {
           this.latitude = results[0].geometry.location.lat();
           this.longitude = results[0].geometry.location.lng();
          // near_place.geometry.location.lat(),dlvr_ng:near_place.geometry.location.lng(),dlvr_add:near_place.name+', '+near_place.formatted_address
        }
      })
    },
    convertTime(time) {
      let hour = time % 12;
      if (time % 12 === 0) {
        hour = 12;
      }
      return hour;
    },
    getAddress() {
      const geocoder = new window.google.maps.Geocoder();
      const address = this.address;
      geocoder.geocode({ 'address': address }, (results, status) => {
        if (status == window.google.maps.GeocoderStatus.OK) {
          // const latitude = results[0].geometry.location.lat();
          // const longitude = results[0].geometry.location.lng();
        }
      });
    },
    changeOperatingHoursIcon() {
      if (!this.showOperatingHrs) {
        this.showOperatingHrs = true;
        this.operatingHoursIcon = this.iconup;
      } else {
        this.operatingHoursIcon = '<i class="fas fa-angle-down"></i>';
        this.showOperatingHrs = false;
      }
    },
    async createStore() {
      this.address = document.getElementById("store_address").value
      this.longitude = document.getElementById("longitude").value
      this.latitude = document.getElementById("latitude").value
      this.spinner = true;
      const isFormCorrect = await this.v$.$validate()
      // you can show some extra alert to the user or just leave the each field to show it's `$errors`.
      if (!isFormCorrect) {
        this.spinner = false;
        return
      }

      const availability = [];
      for (let x = 0; x < this.dayOfWeek.length; x++) {
        availability.push({
          "day": this.dayOfWeek[x].key,
          "opening_time": this.startTime[this.dayOfWeek[x].key],
          "closing_time": this.endTime[this.dayOfWeek[x].key],
          "opened": this.operationDayStatus[this.dayOfWeek[x].key]
        });
      }

      const postData = {
        "merchant_id": parseInt(this.merchantID),
        "branch_name": this.title,
        "image_url": this.imageUrl,
        "description": this.description,
        "phone": this.phone,
        "address": this.address,
        "longitude": this.longitude,
        "latitude": this.latitude,
        "delivery_option": this.deliverOptions,
        "status": parseInt(this.status),
        "pos_name": this.posConfigName,
        "pp_consumer_key": this.consumerKey,
        "pp_secret_key": this.consumerSecret,
        "pp_mobile_guid": this.mobileGuid,
        "parent_branch": this.parentBranch,
        "operation_hours": availability,
        "courier_api_key": this.courierKey,
        "pos_config": this.pos.toString(),
        "printer_ip":this.printerIp.toString(),
        "printer_port":this.printerPort.toString()
      }
      const config = {
        headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
      };
      const jsonParseData = JSON.stringify(postData);
      const url = this.baseUrl + '/branch'
      this.axios.post(url, jsonParseData, config).then((response) => {
        if (response.status === 201) {
          toast.success("Store created successfully");
          this.spinner = false;
          this.$router.push({
            name: 'storelist'
          })
        } else {
          this.spinner = false;
          toast.error(response.data.message);
        }
      })
        .catch((error) => {
          this.spinner = false;
          if (error.response.status === 401) {
            this.$router.push({
              name: 'login'
            });
          }
        })
      return;
    },
    async getParentstore() {
      try {
        const url = `${this.dashBaseUrl}/related-stores/${this.merchantID}`;
        const response = await this.axios.get(url, this.apiRequestConfig());
        if (response) {
          this.parentStores = response.data.data;
          this.strsoptions.push({
            "value": "",
            "name": "All Stores (" + this.parentStores.length + ")"
          })
          for (const store of Object.values(this.parentStores)) {
            this.strsoptions.push({
              "value": store.id,
              "name": store.name + "(" + store.merchant_name + ")"
            })
          }
        }
      } catch (error) {
        //
      }
    }

  },
  mixins: [ReusableFunctionMixins, ReusableDataMixins]

};
</script>
